/* @import url(http://fonts.googleapis.com/css?family="Source Sans Pro"); */

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family="Source Sans Pro":ital,wght@0,200..1000;1,200..1000&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter&family=Noto+Color+Emoji&family=Segoe+UI&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

h1,
h2,
h3 {
    font: 50px "Inter Var", sans-serif;
    font-weight: 700;
    text-align: center;

    @media screen and (max-width: 767px) {
        font: 28px "Inter Var", sans-serif;
        font-weight: 700;
    }

}
.WhoShouldJoin {
    li {
        font: 28px "Source Sans Pro", sans-serif;
    }



    @media screen and (max-width: 767px) {
        li {
            font: 20px "Source Sans Pro", sans-serif;
        }
    }

    ul {
        /* line-height: 40%; */
        line-height: 1.5;
    }

    /* height: 300px; */
} 




.StandOut {
    p {
        font: 26px "Source Sans Pro", sans-serif;
        color: #5E626D;

        @media screen and (max-width: 767px) {
            font: 20px "Source Sans Pro", sans-serif;
            color: #5E626D;
        }
    }
}


.learnDiff {
    font: 26px "Source Sans Pro", sans-serif;
    color: #5E626D;

    @media screen and (max-width: 767px) {
        font: 20px "Source Sans Pro", sans-serif;
        color: #5E626D;
    }
}


.ChooseLnB {
    position: relative;
    overflow: hidden;
    border: 2px solid transparent !important;
    transition: 0.5s;
    line-height: 133.6% !important;
}

.ChooseLnB:hover {
    border: 2px solid #fac243 !important;
    box-sizing: border-box;
    cursor: pointer;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.ChooseLnB::before {
    content: "";
    position: absolute;
    top: 36%;
    left: -14px;
    width: 30px;
    height: 30px;
    background-color: #fac243;
    transform: translate(-50%, -50%);
    transform: rotate(45deg);
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
}

.ChooseLnB:hover::before {
    opacity: 1;
    visibility: visible;
}


.imgLogo {
    height: 95px;
    width: 200px;


    @media screen and (max-width: 767px) {
        height: 50px;
        width: 100px;


    }
}

.Headings {
    a {
        text-decoration: none;
        font: 20px "Source Sans Pro", sans-serif;
        color: #2E3138;
        font-weight: 500;
    }

}

.header-container {
    height: 5%;
    background-color: white;

    filter: drop-shadow(0 0 0.75rem rgba(46, 49, 56, 0.5));
}


.navMenu {
    p {
        font: 25px "Inter Var", sans-serif;
        font-weight: 500;
        color: #2E3138;

        @media screen and (max-width: 767px) {
            font: 18px "Inter Var", sans-serif;
            font-weight: 500;
            color: #2E3138;

        }

    }
}

/* @media (min-width: 992px) {
    .header-container {
        height: 50px;
    }
}

*/

.sitpCard {
    width: 100%;
    /* Set a fixed width */
    min-height: fit-content;
    /* Set a fixed height */
    margin: 10px;

    /* Add margin for spacing between cards */

    @media screen and (max-width: 767px) {
        max-height: 100%;


    }

}

@media (min-width: 768px) {
    .sitpCard {
        width: 640px;
        /* Adjust width for larger screens */
        height: 300px;
        /* Adjust height for larger screens */
    }
}

.card-content {
    height: 500px;
    /* Ensure content fills the card height */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.image-container {
    height: 100%;
    /* height: auto; */
    overflow-y: visible;

    @media screen and (min-width: 991px) {
        margin: -20px 0 -20px 0;
        /* margin: -20px 0; */

    }
}
.image-container img {
    width: 100%; /* Ensures the image spans the full width */
    height: 100%; /* Maintains the image's aspect ratio */
}

.formRegister {
    label {
        font: 20px "Source Sans Pro", sans-serif;
        font-weight: 600;
    }

    input {
        color: #5E626D;
    }

    select {
        color: #5E626D;

    }
}

.GallerySection {
    @media screen and (min-width:767px) {
        margin-top: 150px;
        margin-bottom: 150px;
    }
}

.FirstStep {
    -webkit-text-stroke: 1.5px white;
    -webkit-text-fill-color: #2E3138;
}

.ListStyle {
    font: 20px "Source Sans Pro", sans-serif;
    font-weight: 500;
}

.FooterContent {
    a {
        color: #fff;
        text-decoration: none;
        font-family: "Source Sans Pro", sans-serif;
    }

    span {
        font-family: "Source Sans Pro", sans-serif;

    }

    div {
        text-align: start;
    }

    @media screen and (max-width: 767px) {
        a {
            color: #fff;
            text-decoration: none;
        }
    }

}

@media (max-width: 767px) {
    .FooterContent {
        text-align: center;
    }
}

@media (min-width: 768px) {
    .img-who-should {
        height: 120%;
        margin: -20px 0 -20px 0;
    }
}

@media (min-width: 768px) and (max-width: 1440px) {
    .img-who-should {
        height: 110%;
        margin: 0;
    }
}


.floating-button-container {
    position: fixed;
    bottom: 80px;
    right: 20px;

}

.main-button {
    border-radius: 50px;
    /* padding: 10px 20px; */
    font-size: 16px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.contact-options {
    display: flex;
    flex-direction: column;
    /* align-items: flex-end; */
    gap: 10px;
    pointer-events: none;
    transition: opacity 0.6s ease;
}

.contact-options.active {
    opacity: 1;
    pointer-events: auto;
}

.icon {
    width: 40px;
    height: 40px;
}

.go-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 50px;
    /* padding: 10px 20px; */
    font-size: 16px;
    cursor: pointer;
}

.img-download-btn {
    width: 20px;
    height: 20px;
}

.heading-story {
    font: 32px "Source Sans Pro", sans-serif;
    color: #909090;
    font-weight: bold;

    @media screen and (max-width: 767px) {
        font: 25px "Source Sans Pro", sans-serif;
        font-weight: bold;
    }
}

.qr-code {
    width: 100%;
    max-width: 275px;

    @media screen and (max-width: 767px) {
        width: 180px;
    }
}

.text-heading-app {
    text-align: start;
}

@media screen and (max-width:767px) {
    .text-heading-app {
        text-align: center;
        text-wrap: nowrap;
        margin-top: 50px;
    }
}

.story-image {
    height: 50px;
    width: 50px;
}

.card-story {
    min-height: 350px;
    height: fit-content;
    /* max-height: fit-content; */

    @media screen and (max-width: 767px) {
        min-height: 400px;
        height: min-content;

    }

    /* max-width: 100%; */
}

.heading-about-card {
    color: #1663FC;
    font: 26px "Source Sans Pro", sans-serif;
    font-weight: bold;

    @media screen and (max-width:767px) {
        color: #1663FC;
        font: 20px "Source Sans Pro", sans-serif;
        font-weight: bold;
    }
}

.text-about-card {
    font: 20px "Source Sans Pro", sans-serif;
    text-align: justify;

    @media screen and (max-width: 767px) {
        font: 16px "Source Sans Pro", sans-serif;
        text-align: justify;
    }
}

.maps-sec {
    @media screen and (max-width:767px) {
        height: 400px;
        margin-top: 20px;
        padding: 5px;
    }
}

.padding-certi {
    padding: 5%;

    @media screen and (max-width : 767px) {
        text-align: center;
    }
}


.courseDesc {
    font: 16px "Source Sans Pro", sans-serif;

    @media screen and (max-width: 767px) {
        font: 12px "Source Sans Pro", sans-serif;
    }
}

.imgLogo {
    @media screen and (max-width: 767px) {
        top: 0;
        right: 0;
        position: fixed;
    }
}

.heading-popup {
    font: 26px "Source Sans Pro", sans-serif;
    font-weight: 600;

    @media screen and (max-width: 767px) {
        font: 16px "Source Sans Pro", sans-serif;
        font-weight: 600;
        line-height: 150%;
    }
}



.YtEmbed {
    height: 600px;
    width: 100%;

    @media screen and (max-width: 767px) {
        height: 350px;
    }
}

.SITPHeading {
    font: 26px "Source Sans Pro", sans-serif;
    font-weight: 700;
    font-family: "Source Sans Pro", sans-serif;
    /* text-align: center; */

    @media screen and (max-width: 767px) {
        font: 20px "Source Sans Pro", sans-serif;
        font-weight: 700;
    }
}

.SITPDesc {
    font: 20px "Source Sans Pro", sans-serif;
    font-family: "Source Sans Pro", sans-serif;

    @media screen and (max-width: 767px) {
        font: 16px "Source Sans Pro", sans-serif;
        /* font-weight: 700; */
    }
}

.alumniH1 {
    font: 27px "Source Sans Pro", sans-serif;
    font-weight: 700;

    @media screen and (max-width: 767px) {
        font-weight: 700;
        font: 20px "Source Sans Pro", sans-serif;
    }
}

.alumniH2 {
    font: 19px Source Sans Pro, sans-serif;

    @media screen and (max-width: 767px) {
        font: 15px Source Sans Pro, sans-serif;
    }
}

.btn,
button {
    font: 24px 'Source Sans Pro', sans-serif;
    font-weight: 700;

    @media screen and (max-width:767px) {
        font: 18px 'Source Sans Pro', sans-serif;
        font-weight: 700;

    }
}

.imgCerti {
    border-radius: 80px;
}

.brochurePopup {
    border: 1px solid;
}

/* Popup for download brochure */
.modal-down {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content-down {
    background-color: #fefefe;
    margin: 15% auto;
    /* top: 30%; */
    padding: 20px;
    border: 1px solid #888;
    width: fit-content;
    max-width: 60%;

}

.close-down {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close-down:hover,
.close-down:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.brochurePopup {
    /* background-color: #888; */
    border: none;
    border-radius: 12px;
    width: 100%;
    margin-top: 5px;
}

.courseHead {
    font: 21px Source Sans Pro, sans-serif;
}