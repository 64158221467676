@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    font-family: "Noto Sans", sans-serif;
}

.who_should_join>div {
    background: #2e3138;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    padding: 15px 0;
}

.who_should_join {
    padding-left: 100px;
}

@media screen and (max-width: 768px) {
    .who_should_join>div {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        height: 100%;
    }

    .who_should_join {
        padding-left: 0;
    }

    .who_should_join div img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin: 0 !important;
    }
}