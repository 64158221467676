/* Modal Background */
.modalBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal Content */
  .modalView {
    background: white;
    border-radius: 15px;
    padding: 20px;
    width: 90%;
    max-width: 800px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
  }
  
  /* Close Button */
  .closeButton {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #666;
  }
  
  /* Modal Content Layout */
  .modalContent {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .modalImage {
    width: 100%;
    max-width: 400px;
    height: auto;
    margin: 0 auto;
    border-radius: 8px;
  }
  
  /* Form Styles */
  .modalForm {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .modalForm input,
  .modalForm select {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
  }
  
  .submitButton {
    background: #007bff;
    color: white;
    border: none;
    padding: 12px 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .submitButton:hover {
    background: #0056b3;
  }
  
  .redirectArrow {
    width: 24px;
    height: auto;
  }
  
  /* Success Message */
  .successMessage {
    text-align: center;
    padding: 20px;
    color: #28a745;
  }
  
  /* Mobile Responsive */
  @media (max-width: 768px) {
    .modalView {
      width: 95%;
      padding: 15px;
    }
    
    .modalForm input,
    .modalForm select {
      padding: 10px;
      font-size: 13px;
    }
    
    .submitButton {
      padding: 10px 15px;
      font-size: 14px;
    }
  }
  
  